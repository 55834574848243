import React from 'react';
import Header from './Header';
import '../styles/styles.css';
import styled from 'styled-components';
import loadable from '@loadable/component';

// Bundle this separately so it loads after everything else on client side only
const Shapes = loadable(() => import('./Shapes'));

// Overall container
const Container = styled.div`
  max-width: 1640px;
  margin: 0 auto;

  @media screen and (min-width: 885px) {
    padding: 40px;
  }
`;

// Heading text
const HeaderContainer = styled.h1`
  z-index: 99;
  position: relative;
  padding-left: 14px;
  margin-bottom: 0px;
  top: -350px;
  max-width: 308px;

  @media screen and (min-width: 321px) {
    top: -290px;
  }

  @media screen and (min-width: 885px) {
    max-width: 400px;
    position: fixed;
    bottom: 0;
    top: initial;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 52px;
  }

  @media screen and (min-width: 1050px) {
    max-width: 510px;
  }
`;

// Name in heading
const HeadingTextName = styled.h1`
  font-style: initial;
  display: inline-block;
  z-index: 99;
  position: relative;
  padding-left: 14px;
  margin-bottom: 0px;
  top: -300px;
  max-width: 500px;
  padding-bottom: 12px;

  @media screen and (min-width: 321px) {
    top: -240px;
  }

  @media screen and (min-width: 885px) {
    position: fixed;
    bottom: 170px;
    top: initial;
    padding-left: 0px;
    margin-bottom: 52px;
  }

  @media screen and (min-width: 1050px) {
    bottom: 210px;
  }
`;

// Projects
const ProjectContainer = styled.main`
  position: relative;
  width: 100%;
  margin-top: -300px;

  @media screen and (min-width: 321px) {
    margin-top: -250px;
  }

  @media screen and (min-width: 885px) {
    width: 50%;
    float: right;
    right: 0;
    margin-top: 0px;
    margin-left: 20px;
  }
`;

const Layout = ({ children }) => {
  return (
    <Container>
      <Header />
      <Shapes />

      <HeadingTextName>
        kimcc
      </HeadingTextName>
      <HeaderContainer>
       is a creative developer
      </HeaderContainer>

      <ProjectContainer>
        {children}
      </ProjectContainer>

    </Container>
  )
}

export default Layout;