import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Projects inner container
const ProjectInnerContainer = styled.section`
  width: 100%;
`

// Individual project inner wrapper
const ProjectWrapper = styled.div`
  padding: 0 16px 40px 16px;

  @media screen and (min-width: 885px) {
    padding-left: 25px;
  }

  @media screen and (min-width: 1640px) {
    padding-left: 0px;
  }
`

const Project = ({ content }) => {
  return (
    <ProjectInnerContainer>
      {
        // Loop through content nodes, which is the data for the projects returned from the graphql query in the index page.
        content.map(node => {
          const image = getImage(node.frontmatter.screenshot)

          return (
            <ProjectWrapper>
              <GatsbyImage
                image={image}
                alt={node.frontmatter.screenshot_alt}
              />
              <h4>{node.frontmatter.title}</h4>
              <h3>{node.frontmatter.description}</h3>
              <p className="technology">{node.frontmatter.technology}</p>
            </ProjectWrapper>
          )
        })
      }
    </ProjectInnerContainer>
  )
}

export default Project
