import React from "react"
import Layout from "../components/Layout"
import Project from "../components/Project"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

// Gatsby automatically creates pages for React components that are the default export of files in the src/pages directory.

// If a user tries to visit the URL for a page that doesn’t actually exist, Gatsby will use the src/pages/404.js page component to display an error instead. Go ahead and give it a try! (If you’re trying it on localhost:8000 you’ll need to click the “Preview custom 404 page” button on the development 404 page.)

// When your site gets built, Gatsby will run your page query and pass the resulting data into your page component as a prop called data.
// Your page query needs to be defined outside of your page component. (With useStaticQuery, your query was defined inside your component.)
const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>kimcc's portfolio</title>
        <link rel="canonical" href="https://kimcc.dev" />
      </Helmet>
      <Layout>
        <Project content={data.allMdx.nodes} />
      </Layout>
    </>
  )
}

// Query the project data
export const query = graphql`
  query {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { visible: { eq: "true" } }
      }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      nodes {
        body
        frontmatter {
          title
          description
          technology
          screenshot {
            childImageSharp {
              gatsbyImageData
            }
          }
          screenshot_alt

          visible
          position
        }
      }
    }
  }
`

export default IndexPage
