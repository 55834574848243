import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Navigation = styled.nav`
  flex: 1 1 auto;
  position: absolute;
  z-index: 99;
  margin: 16px;

  @media screen and (min-width: 885px) {
    margin: -5px 0 0 0;
    position: fixed;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  display: inline-block;
  position: relative;
  letter-spacing: 1px;
  margin: 0 32px 20px 0;

  &:hover::before {
    visibility: visible;
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: ${props => props.hoverColor || "var(--white)"};
    visibility: hidden;
    transition: all 0.25s ease-in-out;
  }
`;

const Header = () => {

  return (
    <Navigation>

      <Link to="/about" className="nav-link">
        About
      </Link>

      <StyledLink href="https://github.com/kimcc" target="_blank" rel="noreferrer">Github</StyledLink>

      <StyledLink href="https://dribbble.com/kimcc" target="_blank" rel="noreferrer">Dribbble</StyledLink>

    </Navigation>
  )


}

export default Header;
